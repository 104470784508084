<template>
    <StocksLeaderboard />
</template>

<script>
import StocksLeaderboard from '@/components/StocksLeaderboard.vue';

export default {
    name: 'Home',
    components: {
        StocksLeaderboard,
    }
};
</script>
